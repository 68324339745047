import {
  Box,
  Flex,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import Container from "./Container";
import Link from "./Link";
import SpacecatLogo from "./SpacecatLogo";

export type NavbarMenuItem = "home" | "training" | "blog" | "about";

export interface NavbarProps {
  activeItem?: NavbarMenuItem;
  isSticky?: boolean;
}

interface NavbarItemProps {
  to: string;
  active: boolean;
  children: ReactNode;
}

function NavbarItem(props: NavbarItemProps) {
  const { to, active, children } = props;

  return (
    <Link to={to} color="black" fontWeight={active ? "bold" : "normal"}>
      <Image
        display={["none", "inline-block"]}
        mr="2"
        src={
          active
            ? "/images/navbar/navbar-active.png"
            : "/images/navbar/navbar-inactive.png"
        }
        w="24px"
      />
      {children}
    </Link>
  );
}

export default function Navbar(props: NavbarProps): ReactElement {
  const { activeItem, isSticky } = props;

  return (
    <Box
      as="header"
      bg={isSticky ? "white" : undefined}
      shadow={isSticky ? "base" : undefined}
      transition="all .2s"
    >
      <Container>
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          py="3"
        >
          <Box flexGrow={[1, 0]} textAlign="center">
            <SpacecatLogo
              color="light"
              h={["40px", "48px"]}
              marginInlineStart="0"
            />
          </Box>
          <HStack
            flexGrow={[1, 0]}
            py="4"
            spacing="4"
            as="nav"
            justifyContent="flex-end"
          >
            {/* <NavbarItem to="/training" active={activeItem === "training"}>
              Training
            </NavbarItem> */}
            {/* <NavbarItem to="/blog" active={activeItem === "blog"}>
              Blog
            </NavbarItem> */}
            <NavbarItem to="/about" active={activeItem === "about"}>
              About
            </NavbarItem>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
}
