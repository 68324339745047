import { chakra, Flex, HStack, List, ListItem } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Container from "./Container";
import Link from "./Link";
import SpacecatLogo from "./SpacecatLogo";

export default function Footer(): ReactElement {
  return (
    <chakra.footer bg="gray.800" color="white" pt="6" pb="96">
      <Container>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <SpacecatLogo color="dark" />
          <HStack as={List} spacing="8">
            <ListItem display="inline-block">
              <Link py="1" color="white" to="/legal">
                Terms
              </Link>
            </ListItem>
            <ListItem display="inline-block">
              <Link py="1" color="white" to="/legal/privacy-policy">
                Privacy
              </Link>
            </ListItem>
            <ListItem display="inline-block">
              <Link py="1" color="white" to="/about">
                Contact Us
              </Link>
            </ListItem>
          </HStack>
        </Flex>
      </Container>
    </chakra.footer>
  );
}
