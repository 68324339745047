import { ExternalLinkIcon } from "@chakra-ui/icons";
import React, { ReactNode, useMemo } from "react";

export type Target = "_blank";
export type Rel = "noreferrer";

export const urlSchemeRegex = /^(https?|mailto|tel):/;

export function useLinkTarget(
  to: string,
  _target?: Target
): [Target | undefined, Rel | undefined, ReactNode] {
  const isExternal = useMemo(() => urlSchemeRegex.test(to), [to]);
  const target = _target ?? (isExternal ? "_blank" : undefined);
  const rel = isExternal ? "noreferrer" : undefined;
  const icon = isExternal && (
    <>
      {" "}
      <ExternalLinkIcon w="4" h="4" opacity={0.5} />
    </>
  );
  return [target, rel, icon];
}
