import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from "react";
import { Accent } from "../theme";

const AccentContext = createContext<Accent>("purple");

interface AccentProviderProps {
  accent: Accent;
  children: ReactNode;
}

export function AccentProvider(props: AccentProviderProps): ReactElement {
  const { accent, children } = props;

  return (
    <AccentContext.Provider value={accent}>{children}</AccentContext.Provider>
  );
}

export default function useAccent(): Accent {
  return useContext(AccentContext);
}
