import "./typography.css";
import { extendTheme } from "@chakra-ui/react";

export const primaryColors = {
  heading: "#110d1f",
  text: "#595959",
  beige: "#f6f5f1",
  rule: "#e5e5e6",
};

export const accents = {
  purple: "#594dff",
  blue: "#0088b3",
  cyan: "#00b3a8",
  orange: "#ff824d",
  red: "#ff4d4d",
  pink: "#ff4d8d",
};

const accentPalette = {
  brown: {
    50: "#f5f4ef",
    100: "#e0ded5",
    200: "#cbc8b9",
    300: "#b7b19b",
    400: "#a39b7d",
    500: "#8a8264",
    600: "#6b654e",
    700: "#4d4839",
    800: "#2e2b22",
    900: "#100e0a",
  },
  purple: {
    50: "#e7e4ff",
    100: "#b7b3ff",
    200: "#8880ff",
    300: "#594dff", // default
    400: "#2c1bfe",
    500: "#1301e5",
    600: "#0d00b3",
    700: "#070081",
    800: "#020050",
    900: "#010020",
  },
  blue: {
    50: "#d9fbff",
    100: "#adeeff",
    200: "#7de1ff",
    300: "#4dd5ff",
    400: "#25c9fe",
    500: "#14afe5",
    600: "#0088b3", // default
    700: "#006181",
    800: "#003b50",
    900: "#001520",
  },
  cyan: {
    50: "#d9ffff",
    100: "#acfffb",
    200: "#7dfff7",
    300: "#4dfff4",
    400: "#27fff1",
    500: "#16e6d8",
    600: "#00b3a8", // default
    700: "#008078",
    800: "#004e48",
    900: "#001c19",
  },
  orange: {
    50: "#ffebde",
    100: "#ffc9b0",
    200: "#ffa67f",
    300: "#ff824d", // default
    400: "#fe601c",
    500: "#e54602",
    600: "#b33600",
    700: "#812500",
    800: "#4f1500",
    900: "#200500",
  },
  red: {
    50: "#ffe2e2",
    100: "#ffb1b2",
    200: "#ff7f7f",
    300: "#ff4d4d", // default
    400: "#fe1d1b",
    500: "#e50501",
    600: "#b30000",
    700: "#810000",
    800: "#4f0000",
    900: "#200000",
  },
  pink: {
    50: "#ffe2f1",
    100: "#ffb1ce",
    200: "#ff7fad",
    300: "#ff4d8d", // default
    400: "#fe1e6c",
    500: "#e50652",
    600: "#b30040",
    700: "#81002e",
    800: "#4f001b",
    900: "#20000a",
  },
};

export type Accent = keyof typeof accents;

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Source Sans Pro, sans-serif",
        color: "gray.600",
      },
    },
  },
  colors: {
    primaryColors,
    ...accentPalette,
  },
  fonts: {
    heading: "Recoleta-SemiBold",
  },
  fontSizes: {
    xs: "1rem",
    sm: "1.125rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "1.875rem",
    "2xl": "2.25rem",
    "3xl": "3rem",
    "4xl": "3.75rem",
    "5xl": "4.5rem",
    "6xl": "6rem",
    "7xl": "8rem",
    "8xl": "12rem",
    "9xl": "16rem",
  },
  components: {
    Badge: {
      baseStyle: {
        display: "inline-block",
        px: "3",
        py: "2",
        borderRadius: 999,
        fontWeight: "normal",
        textTransform: "normal",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 999,
      },
      sizes: {
        md: {
          padding: 6,
        },
        lg: {
          padding: 8,
        },
      },
      variants: {
        outline: {
          border: "2px solid",
        },
      },
    },
    Heading: {
      baseStyle: {
        color: "gray.800",
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: "white",
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: "white",
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          bg: "white",
        },
      },
    },
  },
});

export default theme;
