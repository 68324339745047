import {
  As,
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

export interface ContainerProps {
  as?: As;
  children: ReactNode;
  bg?: ChakraContainerProps["bg"];
}

export default function Container(props: ContainerProps): ReactElement {
  const { as, bg, children } = props;
  return (
    <ChakraContainer as={as} bg={bg} maxW="container.lg">
      {children}
    </ChakraContainer>
  );
}
