import { Image, ImageProps, LinkBox, LinkOverlay } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface SpacecatLogoProps extends Omit<ImageProps, "src"> {
  color: "light" | "dark";
}

export default function SpacecatLogo(props: SpacecatLogoProps): ReactElement {
  const {
    display = "block",
    h = "64px",
    mx = "auto",
    alt = "Spacecat",
    color,
    ...rest
  } = props;

  const src =
    color === "light"
      ? "/images/spacecat-wordmark-light.svg"
      : "/images/spacecat-wordmark-dark.svg";

  return (
    <LinkBox>
      <LinkOverlay href="/">
        <Image display={display} h={h} mx={mx} src={src} alt={alt} {...rest} />
      </LinkOverlay>
    </LinkBox>
  );
}
