import useAccent from "@/hooks/useAccent";
import { Target, useLinkTarget } from "@/hooks/useLinkTarget";
import { checkExhausted } from "@/util";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  ColorProps,
  Link as ChakraLink,
  LinkBoxProps as ChakraLinkBoxProps,
  TypographyProps,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React, { ReactElement, ReactNode } from "react";

export interface LinkProps {
  target?: Target;
  py?: ChakraLinkBoxProps["py"];
  px?: ChakraLinkBoxProps["px"];
  color?: ColorProps["color"];
  fontWeight?: TypographyProps["fontWeight"];
  to: string;
  children: ReactNode;
}

export const urlSchemeRegex = /^(https?|mailto|tel):/;

export default function Link(props: LinkProps): ReactElement {
  const {
    target: _target,
    py,
    px,
    color: _color,
    fontWeight,
    to,
    children,
  } = props;

  const accent = useAccent();

  let color: ChakraLinkBoxProps["color"];
  let hover: ChakraLinkBoxProps["color"];
  switch (accent) {
    case "purple":
      color = _color ?? "purple.400";
      hover = _color ?? "purple.600";
      break;
    case "blue":
      color = _color ?? "blue.400";
      hover = _color ?? "blue.500";
      break;
    case "cyan":
      color = _color ?? "cyan.500";
      hover = _color ?? "cyan.600";
      break;
    case "orange":
      color = _color ?? "orange.400";
      hover = _color ?? "orange.500";
      break;
    case "red":
      color = _color ?? "red.400";
      hover = _color ?? "red.500";
      break;
    case "pink":
      color = _color ?? "pink.400";
      hover = _color ?? "pink.500";
      break;
    default:
      checkExhausted(accent);
  }

  const [target, rel, icon] = useLinkTarget(to, _target);

  return (
    <ChakraLink
      target={target}
      rel={rel}
      as={target == null ? GatsbyLink : "a"}
      color={color}
      _hover={{ color: hover, textDecoration: "underline" }}
      fontWeight={fontWeight}
      display="inline-block"
      py={py}
      px={px}
      href={target == null ? undefined : to}
      // @ts-ignore
      to={target == null ? to : undefined}
    >
      {children}
      {icon}
    </ChakraLink>
  );
}
