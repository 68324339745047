import { checkExhausted } from "@/util";
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

export type HeadingLevel = "page" | "section" | "subsection" | "subsubsection";

export interface HeadingProps extends Omit<ChakraHeadingProps, "as"> {
  level: HeadingLevel;
  children: ReactNode;
}

export default function Heading(props: HeadingProps): ReactElement {
  const { id, level, size, children, ...rest } = props;

  switch (level) {
    case "page":
      return (
        <ChakraHeading
          as="h1"
          id={id}
          size={size ?? "xl"}
          mt="6"
          mb="8"
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    case "section":
      return (
        <ChakraHeading
          as="h2"
          id={id}
          size={size ?? "lg"}
          mt="16"
          mb="8"
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    case "subsection":
      return (
        <ChakraHeading
          as="h3"
          id={id}
          size={size ?? "md"}
          mt="8"
          mb="4"
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    case "subsubsection":
      return (
        <ChakraHeading
          as="h4"
          size={size ?? "md"}
          mb="4"
          fontFamily="body"
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    default:
      return checkExhausted(level);
  }
}
