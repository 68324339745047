import { AccentProvider } from "@/hooks/useAccent";
import { chakra, ChakraProvider } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import Helmet from "react-helmet";
import { Sticky, StickyContainer } from "react-sticky";
import theme, { Accent } from "../theme";
import Container from "./Container";
import Footer from "./Footer";
import Navbar, { NavbarMenuItem } from "./Navbar";

interface SpacePageProps {
  accent: Accent;
  title: string;
  bg?: "white" | "beige";
  activeMenuItem?: NavbarMenuItem;
  container?: boolean;
  children: ReactNode;
}

function metaTag(name: string, content: string) {
  return { name, content };
}

function favicon(href: string, sizes: string) {
  return { rel: "icon", type: "image/png", href, sizes };
}

function androidShortcutIcon(href: string, sizes: string) {
  return { rel: "shortcut icon", type: "image/png", href, sizes };
}

function appleTouchIcon(href: string, sizes: string) {
  return { rel: "apple-touch-icon", sizes, href };
}

function canonical(href: string) {
  return { rel: "canonical", href };
}

function stylesheet(href: string) {
  return { rel: "stylesheet", href };
}

const faviconSizes = [32, 57, 76, 96, 128, 192, 228];
const androidShortcutIconSizes = [196];
const appleTouchIconSizes = [120, 152, 180];

const icons = [
  ...faviconSizes.map(size =>
    favicon(`/images/favicon/favicon-${size}.png`, `${size}x${size}`)
  ),
  ...androidShortcutIconSizes.map(size =>
    androidShortcutIcon(
      `/images/favicon/favicon-${size}.png`,
      `${size}x${size}`
    )
  ),
  ...appleTouchIconSizes.map(size =>
    appleTouchIcon(`/images/favicon/favicon-${size}.png`, `${size}x${size}`)
  ),
];

const scripts = [
  // {
  //   async: true,
  //   src: "https://embed.savvycal.com/v1/embed.js",
  // },
  // {
  //   id: "reform-script",
  //   async: true,
  //   src: "https://embed.reform.app/v1/embed.js",
  // },
];

export default function SpacePage(props: SpacePageProps): ReactElement {
  const { title, accent, activeMenuItem, bg, container, children } = props;

  const content = container ? (
    <Container as="main">{children}</Container>
  ) : (
    children
  );

  return (
    <ChakraProvider theme={theme}>
      <AccentProvider accent={accent}>
        <Helmet link={icons} script={scripts} title={`${title} | Spacecat`} />
        <chakra.div fontSize="md">
          <StickyContainer>
            <chakra.div
              maxW="2048px"
              mx="auto"
              boxShadow="base"
              bg={bg === "white" ? "white" : "#f6f5f1"}
            >
              <Sticky>
                {({ style, distanceFromTop }) => (
                  // @ts-ignore
                  <div style={{ ...style, zIndex: 1000 }}>
                    <Navbar
                      activeItem={activeMenuItem}
                      isSticky={distanceFromTop < 0}
                    />
                  </div>
                )}
              </Sticky>
              {content}
              <Footer />
            </chakra.div>
          </StickyContainer>
        </chakra.div>
      </AccentProvider>
    </ChakraProvider>
  );
}
