import { BoxProps, chakra } from "@chakra-ui/react";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { primaryColors } from "../theme";
import Container from "./Container";

export interface SpaceSectionProps extends BoxProps {
  bg?: "white" | "beige";
  ct?: "sm" | "lg";
  cb?: "sm" | "lg";
  children: ReactNode;
}

export default function SpaceSection(props: SpaceSectionProps): ReactElement {
  const { bg = "white", ct, cb, children, ...rest } = props;

  const curves = useMemo(() => {
    const bgImages = [];
    const bgRepeats = [];
    const bgPositions = [];

    if (ct != null) {
      bgImages.push(`url(/images/curves/curve-${bg}-${ct}-t.svg)`);
      bgRepeats.push("no-repeat");
      bgPositions.push("top center");
    }

    if (cb != null) {
      bgImages.push(`url(/images/curves/curve-${bg}-${cb}-b.svg)`);
      bgRepeats.push("no-repeat");
      bgPositions.push("bottom center");
    }

    return {
      backgroundImage: bgImages.join(", "),
      backgroundRepeat: bgRepeats.join(", "),
      backgroundPosition: bgPositions.join(", "),
    };
  }, [cb, bg, ct]);

  return (
    <chakra.div
      bg={bg === "white" ? "white" : primaryColors.beige}
      sx={curves}
      {...rest}
    >
      <Container as="section">{children}</Container>
    </chakra.div>
  );
}
